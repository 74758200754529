<template>
    <div class="flex flex-col-reverse lg:flex-row relative">
        <div class="lg:w-3/4">
            <article id="faqs">
                <h2>How to Get Help</h2>
                <ul>
                    <li>
                        If you have a question or need technical support, you
                        can call: <b>(206) 219-1246</b>
                    </li>
                    <li>
                        Live support via video room, phone and chat are
                        available during the following hours EST:
                        <ul>
                            <li>
                                <b>Week of 2/22</b>
                                <ul>
                                    <li>2/24 10 AM - 12 PM</li>
                                    <li>2/26 2 PM - 4 PM</li>
                                </ul>
                            </li>
                            <li>
                                <b>Week of 3/1</b>
                                <ul>
                                    <li>3/2 12 PM - 2 PM</li>
                                    <li>3/4  2 PM - 6 PM</li>
                                </ul>
                            </li>
                            <li>
                                <b>Week of 3/8</b>
                                <ul>
                                    <li>3/9 4 PM - 6 PM</li>
                                    <li>3/12 10 AM - 12 PM</li>
                                </ul>
                            </li>
                            <li>
                                <b>Week of 3/15</b>
                                <ul>
                                    <li>3/15 4 PM - 6 PM</li>
                                    <li>3/16 12 PM - 2 PM</li>
                                    <li>3/17 10 AM - 1 PM</li>
                                    <li>3/18 1 PM - 5 PM</li>
                                    <li>3/19 10 AM - 2 PM</li>
                                </ul>
                            </li>
                            <li>
                                <b>Week of the Conference </b>
                                <ul>
                                    <li>3/22 - 3/24 10:30 AM - 4:30 PM</li>
                                    <li>3/25 - 10:15 AM - 1:00 PM</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </article>

            <article id="login" class="mt-8">
                <h2>Logging In</h2>
                <p>
                    Since you are accessing these FAQs from within the
                    conference platform, we assume you were able to log in
                    successfully.
                </p>
                <ul>
                    <li>
                        <b>Can someone else log in for me?</b><br />
                        No, your login information is specific to you and should
                        be kept private. For security purposes, you can only be
                        logged in from 1 location at a time.
                    </li>
                    <li>
                        <b
                            >Can I access the platform before the event begins
                            to confirm my equipment will work?</b
                        >
                        <p>
                            Yes, attendees will be given access to the virtual
                            event platform beginning Monday February 22, 2021.
                        </p>
                    </li>
                    <li>
                        <b
                            >A Help Desk meeting room will be available. Visit
                            the
                            <router-link to="/sessions" target="_blank"
                                >“PROGRAM”</router-link
                            >
                            tab to access.</b
                        >
                    </li>
                </ul>
            </article>

            <article id="attendee" class="mt-8">
                <h2>My Attendee Profile</h2>
                <b
                    >As an attendee, you can update your individual profile,
                    including a photo, bio and other information.</b
                >
                <ul>
                    <li class="font-semibold">
                        Navigate to the
                        <router-link to="/attendees" target="_blank"
                            >'Attendees'</router-link
                        >
                        tab
                    </li>
                    <li class="font-semibold">Click “View My Profile”.</li>
                    <li class="font-semibold">
                        You will be able to:
                        <ul>
                            <li>
                                Add an avatar image 600x600 square (.png or
                                .jpg)
                            </li>
                            <li>
                                Edit your profile, including:
                                <ul>
                                    <li>
                                        Email (You decide if you want this to
                                        display, by checking the “display email”
                                        box. It is NOT displayed by default)
                                    </li>
                                    <li>Title</li>
                                    <li>
                                        Phone(and decide if you want this to
                                        display, by checking the “display Phone
                                        number” box (it is NOT displayed by
                                        default)
                                    </li>
                                    <li>
                                        Add your social media account links;
                                        these will show up as icons on your
                                        profile
                                    </li>
                                    <li>Add a short bio</li>
                                    <li>Add info about your organization</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="font-semibold">
                        Things you cannot change:
                        <ul>
                            <li>Your name</li>
                            <li>The name of your organization</li>
                        </ul>
                    </li>
                    <li class="font-semibold">
                        If you need to change either of these, please contact
                        MCAA.
                    </li>
                </ul>
            </article>
            <article id="troubleshooting" class="mt-8">
                <h2>Troubleshooting</h2>

                <ul>
                    <li>
                        <b
                            >How do I get help if I am having technical
                            issues?</b
                        >
                        <p>
                            There are several ways to get help if you are having
                            issues with the site.
                        </p>
                        <ul>
                            <li>
                                Go to the program page and click into the open
                                help desk session
                                <ul>
                                    <li>See above for help desk dates/times</li>
                                </ul>
                            </li>
                            <li>
                                Click on the help icon on the bottom right
                                corner or your screen to chat live with a
                                support person
                            </li>
                            <li>
                                <a href="tel:(206) 219-1246"
                                    >Call (206) 219-1246</a
                                >
                            </li>
                            <li>
                                Email us
                                <a
                                    href="mailto:mailto:bespeakesupport@matrixgroup.net"
                                    >mailto:bespeakesupport@matrixgroup.net</a
                                >
                            </li>
                            <li>
                                Live technical support is available every day of
                                the conference from 9:00am - 6:00pm EST
                            </li>
                        </ul>
                    </li>
                </ul>
            </article>
            <article id="whatshouldido" class="mt-8">
                <h2>What should I do if...</h2>
                <ul>
                    <li>
                        <b>I cannot access a session?</b>
                        <ul>
                            <li>
                                Please click on the help icon on the bottom
                                right of your screen to chat live or go to the
                                <router-link to="/sessions" target="_blank"
                                    >Help Desk</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>
                            I can’t hear/see other attendees in a private
                            meeting or they can’t hear/see me?
                        </b>
                        <ul>
                            <li>
                                Please click on the help icon on the bottom
                                right of your screen to chat live or go to the
                                <router-link to="/sessions" target="_blank"
                                    >Help Desk</router-link
                                >
                            </li>
                        </ul>
                    </li>

                    <li>
                        <b>My audio/video is buffering.</b>
                        <ul>
                            <li>Check the speed on your internet connection</li>
                            <li>
                                Try turning off your camera. A camera can
                                consume a lot of bandwidth
                            </li>
                            <li>
                                Close all other applications running on your
                                device.
                            </li>
                            <li>
                                Make sure that your device is as close to the
                                router as possible. If possible, use a
                                hard-wired connection
                            </li>
                            <li>
                                Click on the help icon on the bottom right of
                                your screen to chat live.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b
                            >I hear an echo when I talk or from other
                            attendees.</b
                        >
                        <ul>
                            <li>Try muting and then unmuting.</li>
                            <li>
                                If possible, do not use the built-in microphone
                                and speakers on your laptop. Use an external
                                headset or speaker.
                            </li>
                        </ul>
                    </li>
                </ul>
            </article>
            <article id="sponsors" class="mt-10">
                <h2>EXHIBIT HALL</h2>

                <ul>
                    <li>
                        <b>Where can I find exhibitor information?</b><br />
                        Click on the
                        <router-link to="/tradeshow" target="_blank"
                            >"EXHIBIT HALL"</router-link
                        >
                        tab in the top menu to view additional information and
                        connect with the exhibitors.
                    </li>
                </ul>
            </article>
            <article id="practices" class="mt-8">
                <h2>Communicating With Other Participants</h2>

                <ul>
                    <li>
                        <b>Where can I see who is attending?</b><br />
                        Click on the
                        <router-link to="/attendees" target="_blank"
                            >"ATTENDEES"</router-link
                        >
                        tab on the top menu bar. You can search for participants
                        by first name, last name, organization or state
                    </li>
                </ul>
            </article>
            <article id="contactparticpants" class="mt-8">
                <h2>Can I contact or message other participants?</h2>

                Yes, you can search for a participant by first name, last name,
                or institution under the
                <router-link to="/attendees" target="_blank"
                    >"ATTENDEES"</router-link
                >
                tab on the top menu bar. Then click on the ‘Send a Message’
                icon.
            </article>
            <article id="privateMeeting" class="mt-4">
                <h2>Private Meetings/Appointments</h2>
                <ul>
                    <li>
                        The
                        <router-link to="/attendees" target="_blank"
                            >"ATTENDEES"</router-link
                        >
                        tab lets you search for attendees so you can schedule a
                        meeting.
                    </li>
                    <li>
                        You can search for other attendees by first name, last
                        name, and organization.
                    </li>
                    <li>
                        Once you find the person you are looking for, click
                        schedule a meeting.
                    </li>
                    <li>
                        You will be able to invite up to 1 person for each
                        appointment.
                    </li>
                    <li>
                        Appointments are available on your
                        <router-link to="/schedule" target="_blank"
                            >‘My Schedule’</router-link
                        >
                        page and will be held in a video room.
                    </li>
                    <li>
                        After you create the invitation, the people invited
                        will.
                        <ul>
                            <li>
                                See a message in the message center (if they are
                                not on the page, they will see a small number
                                next to the Message Center tab showing that
                                there is a message)
                            </li>
                            <li>
                                See the invitation on their
                                <router-link to="/schedule" target="_blank"
                                    >‘My Schedule’
                                </router-link>
                                page.
                            </li>
                            <li>
                                Invitees can accept or decline (on My Schedule);
                                you will receive a message/email with the
                                acceptance message or the decline.
                            </li>
                            <li>
                                Invitees can send you a follow up message,
                                asking to move the time or for you to add people
                                to the meeting invitation
                            </li>
                            <li>As the host, you can</li>
                            <ul>
                                <li>Edit the meeting invitation date/time</li>
                                <li>
                                    Add additional invitees (up to the limit)
                                </li>
                                <li>Cancel the meeting invitation</li>
                            </ul>
                        </ul>
                    </li>
                </ul>
            </article>
            <article id="recordedSession" class="mt-8">
                <h2>Are the sessions being recorded?</h2>
                Yes. All live sessions in the program will be recorded. They
                will be available in the
                <router-link to="/sessions?onDemandFilter=" target="_blank"
                    >‘On-demand’</router-link
                >
                section of the Program within a day of their broadcast.
            </article>
            <article id="Equipment" class="mt-8">
                <h2>Equipment</h2>
                <h3 class="pl-4">General requirements</h3>
                <p class="pl-8">
                    You will need access to either a PC, Mac, tablet, or
                    smartphone. A PC or Mac is recommended. To participate in
                    the meetings, it is recommended that you have an internal or
                    external camera so that you can be seen on video.
                </p>
                <h3 class="pl-4">Microphones</h3>
                <p class="pl-8">
                    Clear audio can make a big difference. Laptop/phone audio
                    can work, but audio through an external microphone
                    (connected by wire or Bluetooth) can be a huge plus.
                </p>
                <h3 class="pl-4">Browser</h3>
                <p class="pl-8">
                    The conference will support the latest versions of Chrome,
                    Safari, Firefox, and Edge. Internet Explorer 11 is not fully
                    supported and you should use a different browser.
                </p>
                <p class="pl-8">
                    If you plan to participate from a tablet or smartphone, know
                    that some features will be harder to access, although
                    watching sessions will work just fine.
                </p>
            </article>
            <article id="private" class="mt-8">
                <h2>Accessing Private Meetings</h2>
                <p>
                    There's a lot of tech going on. Please make sure you can
                    access the private meeting rooms by visiting the
                    <router-link to="/sessions" target="_blank"
                        >Help Desk</router-link
                    >. While there:
                </p>
                <ul>
                    <li>Make sure you can see other people on video.</li>
                    <li>
                        Make sure you have given your browser permission to use
                        your speaker and microphone.
                    </li>
                </ul>
            </article>
            <!-- <h3>Have a Terrific Midwinter! Thank you for participating!</h3> -->
        </div>
        <div class="lg:w-1/4 lg:pl-8">
            <ul>
                <li>
                    <a href="#faqs">How to Get Help</a>
                    <ul>
                        <li><a href="#login">Logging In</a></li>
                        <li><a href="#attendee">My Attendee Profile</a></li>
                        <li><a href="#troubleshooting">Troubleshooting</a></li>
                        <li>
                            <a href="#whatshouldido">What should I do if...</a>
                        </li>
                        <li><a href="#sponsors">EXHIBIT Hall</a></li>
                    </ul>
                </li>
                <li>
                    <a href="#practices"
                        >Communicating With Other Participants</a
                    >
                </li>
                <li>
                    <a href="#contactparticpants"
                        >Can I contact or message other participants?</a
                    >
                </li>
                <li>
                    <a href="#privateMeeting">Private Meetings/Appointments </a>
                </li>
                <li>
                    <a href="#recordedSession"
                        >Are the sessions being recorded?
                    </a>
                </li>
                <li>
                    <a href="#Equipment">Equipment </a>
                </li>
                <li>
                    <a href="#private">Accessing Private Meetings </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "midwinter"
};
</script>

<style lang="scss" scoped>
@import "../../styles/setup/colors";
@import "../../styles/views/help";
</style>
